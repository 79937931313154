import React, { FunctionComponent } from "react"
import { graphql, PageProps } from "gatsby"
import * as SCM from "../types"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Event from "../components/Event"

type Props = PageProps & {
  data: {
    eventPage: SCM.EventPage
  }
}

const EventTemplate: FunctionComponent<Props> = props => {
  const eventPage = props.data.eventPage

  return (
    <Layout>
      <SEO
        locale={eventPage.locale}
        uri={props.uri}
        breadcrumb={[
          {
            position: 1,
            name: "Events",
            slug: "/events",
          },
        ]}
        {...eventPage.seo}
      />
      <Event {...eventPage} />
    </Layout>
  )
}

export default EventTemplate

export const query = graphql`
  query EventPageQuery {
    eventPage {
      ...eventPageFragment
    }
  }
`
